import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RefundableProvider } from 'protect-group-dynamic-refundable-react';

const options = {
    environment: 'test',
    currencyCode: 'USD',
    languageCode: 'en',
    vendorCode: 'ven_local_2bbdc8d3344b42b7acedfd04b41ad9d9',
    eventDateFormat: 'YYYY/MM/DD',
    containerSize: 'medium',
    useSaleAction: true
};

ReactDOM.render(
    <React.StrictMode>
        <RefundableProvider options={options}>
            <App />
        </RefundableProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
