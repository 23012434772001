import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import{
    faBed,
    faCalendar,
    faPlane,
    faPerson,
    faHotel
} from '@fortawesome/free-solid-svg-icons'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "./Header.css"
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {dateState} from "../recoil/atom";

const Header = ({type}) => {
    const [openOptions, setOpenOptions] = useState(false)
    const [options, setOptions] = useState(
        {
            adult: 1,
            children: 0,
            room: 1
        }
    )
    const [destination, setDestination] = useState("")
    const navigate = useNavigate()
    const setDate = useSetRecoilState(dateState);
    const newDate = {
        startDate: null,
        endDate: null,
        key: "dateState"
    }

    const handleOption = (name, operation) => {
        setOptions((prev =>{
            return {
            ...prev,
            [name]: operation === "increase" ? options[name] + 1 : options[name] -1,
        }}))
    }

    const handleDateSelection = (startDate, endDate) => {
        if(startDate!=null){
            newDate.startDate = startDate
        }

        if(endDate!=null){
            newDate.endDate = endDate
        }
    };

    const handleDate = () =>{

        if(newDate.startDate === null){
            newDate.startDate = new Date()
        }else{
            setDate(newDate)
        }
    }

    const handleSearch = () => {
        handleDate();
        navigate("/hotels", {state: {destination, options}})
    }

    return (
        <div className={"header"}>
            <div className={type === "list" ? "headerContainer listMode" : "headerContainer"}>
                {type !== "list" &&
                    <>
                        <div className="headerSearch">
                            <div className="headerSearchItem">
                                <FontAwesomeIcon icon={faBed} className="headerIcon"/>
                                <select required className="headerSearchInput" onChange={e => setDestination(e.target.value)}>
                                    <option value="noSelection" defaultValue>Select your Destination!</option>
                                    <option value="argentina">Argentina</option>
                                    <option value="brazil">Brazil</option>
                                    <option value="mexico">Mexico</option>
                                    <option value="chile">Chile</option>
                                    <option value="uruguay">Uruguay</option>
                                </select>
                            </div>
                            <div className="headerSearchItem">
                                From
                                <input required type={"date"} min={(new Date(Date.now() + 86400000)).toISOString().split('T')[0]} onChange={(e) => handleDateSelection(e.target.value, null)}/>
                                To
                                <input required type={"date"} min={(new Date(Date.now() + 86400000)).toISOString().split('T')[0]} onChange={(e) => handleDateSelection(null, e.target.value)}/>
                            </div>
                            <div className="headerSearchItem">
                                <FontAwesomeIcon icon={faPerson} className="headerIcon"/>
                                <span
                                    onClick={() => setOpenOptions(!openOptions)}
                                    className="headerSearchText">{`${options.adult} adult • ${options.children} children • ${options.room} • room`}</span>
                                {openOptions && <div className="options">
                                    <div className="optionItem">
                                        <span className="optionText">Adult</span>
                                        <div className="optionCounter">
                                            <button
                                                disabled={options.adult < 1}
                                                className="optionCounterButton"
                                                onClick={() => handleOption("adult", "decrease")}>
                                                -
                                            </button>
                                            <span className="optionCounterNumber">{options.adult}</span>
                                            <button className="optionCounterButton "
                                                    onClick={() => handleOption("adult", "increase")}>+
                                            </button>
                                        </div>
                                    </div>
                                    <div className="optionItem">
                                        <span className="optionText">Children</span>
                                        <div className="optionCounter">
                                            <button
                                                disabled={options.children < 1}
                                                className="optionCounterButton"
                                                onClick={() => handleOption("children", "decrease")}>
                                                -
                                            </button>
                                            <span className="optionCounterNumber">{options.children}</span>
                                            <button className="optionCounterButton"
                                                    onClick={() => handleOption("children", "increase")}>+
                                            </button>
                                        </div>
                                    </div>
                                    <div className="optionItem">
                                        <span className="optionText">Room</span>
                                        <div className="optionCounter">
                                            <button
                                                disabled={options.room < 1}
                                                className="optionCounterButton "
                                                onClick={() => handleOption("room", "decrease")}>
                                                -
                                            </button>
                                            <span className="optionCounterNumber">{options.room}</span>
                                            <button className="optionCounterButton"
                                                    onClick={() => handleOption("room", "increase")}>+
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="headerSearchItem">
                                <button className="headerBtn" onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};
export default Header;