import React from 'react';
import "./Featured.css"
import FeaturedItem from "./FeaturedItem";
import ConradImg from "../images/hotels/conradHotel.jpg"
import CasaPuebloImg from "../images/hotels/casapueblo.jpg"
import SisaiImg from "../images/hotels/sisai.jpg"
import HyattImg from "../images/hotels/hyatt.jpg"
const Featured = () => {

    return (
        <div className="featured">
            <FeaturedItem
                hotelName={"Conrad"}
                id={5}
                imgSrc={ConradImg}
            />
            <FeaturedItem
                hotelName={"Hyatt"}
                id={6}
                imgSrc={HyattImg}
            />
            <FeaturedItem
                hotelName={"Sisai"}
                id={7}
                imgSrc={SisaiImg}
            />
            <FeaturedItem
                hotelName={"Casa pueblo"}
                id={22}
                imgSrc={CasaPuebloImg}
            />
        </div>
    );
};
export default Featured;