import React from 'react';
import "../../components/footer/Footer.css"

const Footer = () => {

    return (
        <>
            <div className="footerContainer">
                <div className="footer">
                    <div className="top">
                        <div className="footerTitles">
                            <p>Discover <span>Protect Hotels</span>: Your go-to for seamless hotel bookings worldwide. Book now for unforgettable experiences!</p>
                        </div>
                    </div>
                    <div className="bottom">
                        <div>
                            <h4>Info</h4>
                            <a href="#">Status</a>
                            <a href="#">Licence</a>
                        </div>
                        <div>
                            <h4>Help</h4>
                            <a href="#">Support</a>
                            <a href="#">Troubleshooting</a>
                            <a href="#">Contact Us</a>
                        </div>
                        <div>
                            <h4>Others</h4>
                            <a href="#">Terms of Service</a>
                            <a href="#">Privacy Policy</a>
                            <a href="#">Licence</a>
                        </div>
                        <div className="footerIcons">
                            <h4>Social media</h4>
                            <a href="#">
                                <i className="fa-brands fa-facebook-square"></i>
                            </a>
                            <a href="#">
                                <i className="fa-brands fa-instagram-square"></i>
                            </a>
                            <a href="#">
                                <i className="fa-brands fa-youtube-square"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__copyright">
                <p>&copy; Hotel Booking. All rights reserved.</p>
            </div>
        </>
    )
};

export default Footer;