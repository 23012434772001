import React from 'react'
import Navbar from "../../components/navbar/Navbar";
import "./Home.css"
import Header from "../../components/header/Header";
import Featured from "../../components/featured/Featured";
import "../../components/featured/Featured.css";
import Deal from "../../components/deal/Deal";
import Offer from "../../components/offer/offer";
import Trending from "../../components/trending/Trending";
import "../../components/footer/Footer";
import Footer from "../../components/footer/Footer";

const Home = () => {
    return(
        <div>
            <Navbar/>
            <Header/>
            <Deal/>
            <div className="homeContainer">
                <div className="featuredTitle">
                    <h1>Uruguayan's top 4</h1>
                    <img className="fTitleImg"
                         src="https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg" alt=""/>
                </div>
                <Featured/>

                <div className="offerTexts">
                    <h2>Offers</h2>
                    <p>Promotions, deals, and special offers for you</p>
                </div>
                <Offer/>

                <Trending/>

                <Footer/>
            </div>
        </div>
    )
}

export default Home;