import { atom } from 'recoil';

const currentDate = new Date();
const nextDayDate = new Date(currentDate.getTime());
nextDayDate.setDate(currentDate.getDate() + 1);
export const dateState = atom({
    key: 'dateState',
    default: {
        startDate: nextDayDate,
        endDate: nextDayDate,
    },
});

export const refundState = atom({
    key: 'refundState',
    default: {
        selection: false,
    }
});