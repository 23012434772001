import React from 'react';
import './Deal.css';

const Deal = () => {
    return (
        <div className="sale">
            <div className="saleContainer">
                <h1 className="saleTitle">Best South American deals </h1>
                <p className="saleDesc">Book your suite for the best price of the market</p>
                <button className="saleBtn">Discover</button>
            </div>
        </div>
    );
};

export default Deal;