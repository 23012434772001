export const hotels = [
    {
        id: 1,
        image: 'https://images.pexels.com/photos/258154/pexels-photo-258154.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        destination: 'chile',
        title: "The Ritz-Carlton, Santiago",
        distance: "300m from center",
        taxiOp: "Free airport taxi",
        subtitle: "Luxury suite in downtown Santiago",
        features: "Spa facilities · Rooftop pool · City views",
        rating: 8.5,
        price: "$120",
    },
    {
        id: 2,
        image: 'https://img.freepik.com/foto-gratis/hermosa-piscina-lujo-al-aire-libre-hotel-resort_74190-7433.jpg',
        destination: 'chile',
        title: "Hotel Palacio Astoreca",
        distance: "400m from center",
        taxiOp: "Airport shuttle available",
        subtitle: "Cozy boutique hotel in Valparaíso",
        features: "Ocean view rooms · Historic building",
        rating: 9.0,
        price: "$90",
    },
    {
        id: 3,
        image: 'https://images7.alphacoders.com/362/362619.jpg',
        destination: 'uruguay',
        title: "Fasano Punta del Este",
        distance: "200m from beach",
        taxiOp: "Shuttle to Montevideo available",
        subtitle: "Beachfront resort in Punta del Este",
        features: "Private beach · Spa services · Fine dining",
        rating: 9.2,
        price: "$180",
    },
    {
        id: 4,
        image: 'https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        destination: 'uruguay',
        title: "Alma Histórica Boutique Hotel",
        distance: "500m from center",
        taxiOp: "Free parking available",
        subtitle: "Historic hotel in Montevideo's old town",
        features: "Colonial architecture · Cultural district",
        rating: 8.8,
        price: "$110",
    },
    {
        id: 5,
        image: 'https://images.unsplash.com/photo-1611892440504-42a792e24d32?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGFiaXRhY2klQzMlQjNuJTIwZGUlMjBob3RlbHxlbnwwfHwwfHx8MA%3D%3D',
        destination: 'uruguay',
        title: "Conrad Punta del Este Resort & Casino",
        distance: "1km from beach",
        taxiOp: "Shuttle to Montevideo available",
        subtitle: "Luxury beach resort in Punta del Este",
        features: "Beachfront rooms · Spa · Gourmet dining",
        rating: 9.6,
        price: "$220",
    },
    {
        id: 6,
        image: 'https://images.unsplash.com/photo-1564501049412-61c2a3083791?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGhvdGVsc3xlbnwwfHwwfHx8MA%3D%3D',
        destination: 'uruguay',
        title: "Hyatt Centric Montevideo",
        distance: "400m from center",
        taxiOp: "Airport shuttle available",
        subtitle: "Modern hotel in Montevideo",
        features: "Rooftop pool · Fitness center",
        rating: 8.9,
        price: "$150",
    },
    {
        id: 7,
        image: 'https://lh3.googleusercontent.com/proxy/151BFCI9sdPUGiEpdkN2UQeQMmrT2c4q-_QyFZrq7Rz2abWYhfDVdUEtPmokdHcWlU05sXt-iH8BJUMJCK6jXKDDKBL_jDI91KS4bhC_CH_6Nl8cK049O8lHhxL_DLTlHS0UfHC0JpOyp703s3LY2f-_9fGUEA=s680-w680-h510',
        destination: 'uruguay',
        title: "Sisai Hotel Boutique",
        distance: "300m from beach",
        taxiOp: "Free airport shuttle",
        subtitle: "Beachfront hotel in Punta del Este",
        features: "Ocean view rooms · Spa facilities",
        rating: 9.1,
        price: "$200",
    },
    {
        id: 22,
        image: 'https://visitaruruguay.com/wp-content/uploads/2019/03/casapueblo.jpg',
        destination: 'uruguay',
        title: "Casa pueblo",
        distance: "50m from beach",
        taxiOp: "Free airport shuttle",
        subtitle: "Beachfront hotel in Punta del Este",
        features: "Ocean view rooms · Spa facilities",
        rating: 9.9,
        price: "$250",
    },
    {
        id: 8,
        image: 'https://www.hdhotels.com/content/imgsxml/panel_herohotel/1.-cabecerahdals290.jpg',
        destination: 'brazil',
        title: "Belmond Copacabana Palace",
        distance: "200m from beach",
        taxiOp: "Airport pickup available",
        subtitle: "Luxury beach resort in Rio de Janeiro",
        features: "Private beach · Spa · Fine dining",
        rating: 9.4,
        price: "$250",
    },
    {
        id: 9,
        image: 'https://images.pexels.com/photos/258154/pexels-photo-258154.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        destination: 'brazil',
        title: "Hotel Emiliano São Paulo",
        distance: "500m from city center",
        taxiOp: "Discounted tours available",
        subtitle: "City hotel in São Paulo",
        features: "Rooftop bar · Business amenities",
        rating: 8.7,
        price: "$180",
    },
    {
        id: 10,
        image: 'https://images.unsplash.com/photo-1611892440504-42a792e24d32?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGFiaXRhY2klQzMlQjNuJTIwZGUlMjBob3RlbHxlbnwwfHwwfHx8MA%3D%3D',
        destination: 'brazil',
        title: "Nannai Resort & Spa",
        distance: "300m from beach",
        taxiOp: "Local cuisine cooking classes",
        subtitle: "Beachfront hotel in Florianópolis",
        features: "Infinity pool · All-inclusive packages",
        rating: 8.9,
        price: "$230",
    },
    {
        id: 11,
        image: 'https://images.unsplash.com/photo-1564501049412-61c2a3083791?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGhvdGVsc3xlbnwwfHwwfHx8MA%3D%3D',
        destination: 'mexico',
        title: "Four Seasons Hotel Mexico City",
        distance: "200m from historic district",
        taxiOp: "Free airport shuttle",
        subtitle: "Colonial-style hotel in Mexico City",
        features: "Courtyard garden · Cultural tours",
        rating: 9.0,
        price: "$190",
    },
    {
        id: 12,
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSztD4YQLQQEBiLftvCpoZnibpd5qAM6PX59Q&usqp=CAU',
        destination: 'mexico',
        title: "NIZUC Resort & Spa",
        distance: "300m from beach",
        taxiOp: "Local cuisine cooking classes",
        subtitle: "Resort in Cancún",
        features: "Infinity pool · All-inclusive packages",
        rating: 8.8,
        price: "$110",
    },
    {
        id: 13,
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Uk42Whbytk9G-3UYTXuLTezoAsJiWd3bUQ&usqp=CAU',
        destination: 'argentina',
        title: "Hotel Buenos Aires",
        distance: "200m from city square",
        taxiOp: "Airport transfer included",
        subtitle: "Boutique hotel in Buenos Aires",
        features: "Art deco design · Tango classes · Gourmet dining",
        rating: 9.5,
        price: "$200",
    },
    {
        id: 14,
        image: 'https://images.unsplash.com/photo-1520250497591-112f2f40a3f4?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGhvdGVsc3xlbnwwfHwwfHx8MA%3D%3D',
        destination: 'argentina',
        title: "Hotel Rosario",
        distance: "1km from stadium",
        taxiOp: "Free shuttle to city center",
        subtitle: "Modern hotel in Rosario",
        features: "Fitness center · Business facilities",
        rating: 8.7,
        price: "$150",
    },
    {
        id: 15,
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ606aUgu0CSByanBIyZzcE0pVu9gtjSWnx1Q&usqp=CAU',
        destination: 'argentina',
        title: "Hotel Mendoza",
        distance: "500m from city center",
        taxiOp: "Discounted tours available",
        subtitle: "City hotel in Mendoza",
        features: "Rooftop bar · Business amenities",
        rating: 8.9,
        price: "$180",
    },
    {
        id: 16,
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBCeHFFwXSAtEXn-W99Upbf0bTfVkRqB74Bg&usqp=CAU',
        destination: 'argentina',
        title: "Hotel Córdoba",
        distance: "600m from historic district",
        taxiOp: "Free airport shuttle",
        subtitle: "Colonial-style hotel in Córdoba",
        features: "Courtyard garden · Cultural tours",
        rating: 9.0,
        price: "$190",
    }
    ]
