import "./Navbar.css"
import NavbarList from "./NavbarList";

const Navbar = () => {
    return (
        <div className={"navbar"}>
            <div className="navContainter">
                <a href={"/"} className="logo">Protect Hotels</a>
                <NavbarList/>
                <div className="navItems">
                    <button className="navButton">Sign Up</button>
                    <button className="navButton">Login</button>
                </div>
            </div>
        </div>
    );
};

export default Navbar;