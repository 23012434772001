import React from 'react';
import "../offer/offer.css";

const Offer = () => {
    const offerDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * 30));
    const monthName = offerDate.toLocaleString('default', { month: 'long' });

    return (
        <div className="offerContainer">
            <div className="offer">
                <h3>New year, new adventures</h3>
                <p>Save 15% or more when you book and stay before {monthName} 1, {offerDate.getFullYear()}</p>
                <button className="offerBtn">Find Early {offerDate.getFullYear()} offers</button>
            </div>
        </div>
    );
};

export default Offer;