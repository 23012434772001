import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/home/Home"
import Lists from "./pages/list/Lists"
import Checkout from "./pages/checkout/Checkout"
import { RecoilRoot } from 'recoil';
import '@fortawesome/fontawesome-free/css/all.css';
import React, { useState } from "react";
import ThankYou from "./pages/thankyou/ThankYou";

function App(){

    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    return(
        <RecoilRoot>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path="/hotels" element={<Lists/>}></Route>
                    <Route path="/checkout/:id" element={<Checkout/>}></Route>
                    <Route path="/thank you/:bookingRef" element={<ThankYou/>}></Route>
                </Routes>
            </BrowserRouter>
        </RecoilRoot>
    )
}

export default App;

