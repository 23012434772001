import React from 'react'
import "./Trending.css"
import { trendingItemsA, trendingItemsB } from "../../components/trending/TrendingItems"

const Trending = () => {
    return (
        <div>
            <div className="trendingTitle">
                <h2>Trending destinations</h2>
                <p>Most popular choices for travelers</p>
            </div>

            <div className="trendingContainer">
                <div className="tFirstRow">
                    {
                        trendingItemsA.map((item, index) => (
                            <div className="trendingItem" key={index}>
                                <a href={item.path}><img className="trendingImg"  src={item.img}/></a>
                                <div className="country">
                                    <p>{item.country}</p>
                                    <img
                                        src={item.flagImg}
                                        alt=""/>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="tSecondRow">
                    {
                        trendingItemsB.map((item, index) => (
                            <div className="trendingItem2" key={index}>
                                <a href={item.path}><img className="trendingImg" src={item.img}/></a>
                                <div className="country">
                                    <p>{item.country}</p>
                                    <img
                                        src={item.flagImg}
                                        alt=""/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Trending;