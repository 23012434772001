import React from 'react';
import ArgentinaImg from "../images/Flag-Argentina.png"
import UruguayImg from "../images/Flag-uruguay.png"
import BrazilImg from "../images/Flag-brazil.png"
import ChileImg from "../images/Flag-chile.png"
import MexicoImg from "../images/Flag-mexico.png"
import ArgHotel from "../images/hotels/almaHistorica.jpg"
import BrHotel from "../images/hotels/BraImg.jpg"
import MxHotel from "../images/hotels/MxImg.jpg"
import ChiHotel from "../images/hotels/ChileImg.jpg"
import UyHotel from "../images/hotels/UyImg.jpeg"
export const trendingItemsA = [
    {
        country: 'Argentina',
        path: '/checkout/16',
        img: ArgHotel,
        flagImg: ArgentinaImg
    },
    {
        country: 'Brazil',
        path: '/checkout/15',
        img: BrHotel,
        flagImg: BrazilImg
    }
]

export const trendingItemsB = [
    {
        country: 'Mexico',
        path: '/checkout/14',
        img: MxHotel,
        flagImg: MexicoImg
    },
    {
        country: 'Chile',
        path: '/checkout/13',
        img: ChiHotel,
        flagImg: ChileImg
    },
    {
        country: 'Uruguay',
        path: '/checkout/11',
        img: UyHotel,
        flagImg: UruguayImg
    }
]
