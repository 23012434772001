import React from 'react';
import "./SearchItem.css";
import {hotels} from "../../Constants";
import {useNavigate} from "react-router-dom";

const SearchItem = ({ destination, id }) => {
    const filteredData = hotels.filter(item => {
        const destinationToCheck = destination.trim() || 'uruguay';
        return item.destination === destinationToCheck;
    });

    const navigate = useNavigate()

    const handleBookNow = (idSelected) => {
        navigate(`/checkout/${idSelected}`);
    };

    return (
        filteredData.map(item => (
            <div key={item.id} className="searchItem">
                <img src={item.image} alt="" className="siImg"/>
                <div className="siDescription">
                    <h1 className="siTitle">{item.title}</h1>
                    <span className="siDistance">{item.distance} from center</span>
                    <span className="siTaxiOp">{item.taxiOp}</span>
                    <span className="siSubtitle">{item.subtitle}</span>
                    <span className="siFeatures">{item.features}</span>
                    <span className="siCancel0p">Free cancellation</span>
                    <span className="siCancelOpSubtitle">{item.cancelOpSubtitle}</span>
                </div>
                <div className="siDetails">
                    <div className="siRating">
                        <span>Rating</span>
                        <button>{item.rating}</button>
                    </div>
                    <div className="siDetailTexts">
                        <span className="siPrice">{item.price}</span>
                        <span className="siTaxOp">Includes taxes and fees</span>
                        <button className="siCheckButton" onClick={() => handleBookNow(item.id)}>Book now</button>
                    </div>
                </div>
            </div>
    )));
};

export default SearchItem;