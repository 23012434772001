import React from 'react';
import "../../components/hotelView/HotelView.css";
const HotelView = ({item, refundValue}) => {
    return (
        <div key={item.id} className="hContainer">
            <img src={item.image} alt="" className="hImg"/>
            <div className="hDesc">
                <h1 className="hTitle">{item.title}</h1>
                <span className="hDistance">{item.distance} from center</span>
                <span className="hSubtitle">{item.subtitle}</span>
                <span className="hFeatures">{item.features}</span>
                <span className="hTaxiOp">Includes taxes and fees</span>
            </div>

            <div className="hDetails">
                <div className="hSide">
                    <div className="hRating">
                        <span className="hRatingTitle">Rating   </span>
                        <span className="hRatingValue">{item.rating}</span>
                    </div>
                    <div className="hCost">
                        <div className="column">
                            <span className="hCostValue">Price</span>
                            <span className="hRefund">Protection</span>
                        </div>
                        <div className="column">
                            <span className="hCostValue">${parseInt(item.price.replace("$", "")).toLocaleString('es-ES', {minimumFractionDigits: 2})}</span>
                            <span className="hRefund">${refundValue}</span>
                        </div>
                    </div>
                </div>
                <span className="hPrice">${parseInt(item.price.replace("$", "")) + refundValue}</span>
            </div>
        </div>
    );
};

export default HotelView;