import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBed, faHotel, faPlane, faStar} from "@fortawesome/free-solid-svg-icons";

const NavbarList = () => {
    return (
        <div className="headerList">
            <div className="headerListItem active">
                <FontAwesomeIcon icon={faHotel}/><p>Hotels</p>
            </div>
            <div className="headerListItem">
                <FontAwesomeIcon icon={faPlane}/><p>Flights</p>
            </div>
            <div className="headerListItem">
                <FontAwesomeIcon icon={faBed}/><p>Hostels</p>
            </div>
            <div className="headerListItem">
                <FontAwesomeIcon icon={faStar}/><p>Top choice</p>
            </div>
        </div>
    );
};

export default NavbarList;