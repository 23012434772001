import {useParams} from "react-router-dom";
import "../../pages/thankyou/ThankYou.css"
import {useRecoilValue} from "recoil";
import { refundState } from "../../components/recoil/atom";

const ThankYou = () => {
    const {bookingRef} = useParams()
    const isRefundable = useRecoilValue(refundState).selection

    return (
        <div className="tyContainer">
            <div className="bg-white p-10 rounded-lg shadow-lg text-center">
                <h2 className="text-3xl text-gray-900 font-semibold mb-4">Thank you for your purchase!</h2>
                <p className="text-lg text-gray-700">We appreciate your business. If you have any questions or concerns,
                    feel free to <a href="#" className="text-blue-500 hover:underline">contact us</a>.</p>
            </div>
            {
                isRefundable &&
                <div className="rbContainer">
                    <div id="DIV_2">
                        <span id="span_3"><strong id="strong_4">Refundable Booking</strong></span>
                        <h6 id="H6_5">
                            To make changes to your booking or where it is cancelled or changed by the provider, please
                            contact our
                            Customer Service Team at <strong id="strong_6"><i id="i_7">
                            suport@protectgroup.com</i></strong>.
                        </h6>
                        <h6 id="h6_8">
                            This is a refundable booking. If you are unable to attend your booking due to unforeseen
                            circumstances and can provide evidence as listed in the
                            <strong id="strong_9">Terms &amp; Conditions</strong> <a
                            href="https://www.refundable.me/extended/en/" id="a_10"><i id="i_11">here</i></a>,
                            you may be entitled to a full refund.
                        </h6>
                        <h6 id="h6_12">
                            Your booking reference number is <i id="i_13">{bookingRef}</i>.
                            Apply for a refund using the form
                            <a href={`https://test.form.refundable.me/forms/refund?bookingReference=${bookingRef}&memberId=986`}
                               id="a_14"><i id="i_15">
                                here.
                            </i></a>
                        </h6>
                    </div>
                </div>
            }
        </div>
    );
};
export default ThankYou;
