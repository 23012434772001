import {useState} from 'react';
import Navbar from "../../components/navbar/Navbar";
import "./List.css"
import {useLocation, useNavigate} from "react-router-dom";
import Footer from "../../components/footer/Footer";
import SearchItem from "../../components/searchitem/SearchItem";
const Lists = () => {

    const location = useLocation();
    const [destination, setDestination] = useState(location.state.destination)
    const [openDate, setOpenDate] = useState(false)
    const [options, setOptions] = useState(location.state.options)
    const navigate = useNavigate()

    const handleSearch = () => {
        navigate("/hotels", {state: {destination, options}})
    }

    return (
        <div>
            <div className="nav">
                <Navbar/>
            </div>
            <div className="listContainer">
                <div className="listWrapper">
                    <div className="lsSearch">
                        <h1 className="lsTitle">Search</h1>
                        <div className="lsItem">
                            <select className="headerSearchInput" onChange={e => setDestination(e.target.value)}>
                                <option value="noSelection" defaultValue>Select your Destination!</option>
                                <option value="argentina">Argentina</option>
                                <option value="brazil">Brazil</option>
                                <option value="mexico">Mexico</option>
                                <option value="chile">Chile</option>
                                <option value="uruguay">Uruguay</option>
                            </select>
                        </div>
                        <div className="lsItem">
                            <label htmlFor="">Options</label>
                            <div className="lsOptions">
                                <div className="lsOptionItem">
                                <span className="lsOptionText">
                                    Min price <small>per night</small>
                                </span>
                                    <input type="number" className="lsOptionInput"/>
                                </div>
                                <div className="lsOptionItem">
                                <span className="lsOptionText">
                                    Max price <small>per night</small>
                                </span>
                                    <input type="number" className="lsOptionInput"/>
                                </div>
                                <div className="lsOptionItem">
                                <span className="lsOptionText">
                                    Adult
                                </span>
                                    <input type="number" className="lsOptionInput" min={1} placeholder={options.adult}/>
                                </div>
                                <div className="lsOptionItem">
                                <span className="lsOptionText">
                                    Children
                                </span>
                                    <input type="number" min={0} className="lsOptionInput"
                                           placeholder={options.children}/>
                                </div>
                                <div className="lsOptionItem">
                                <span className="lsOptionText">
                                    Room
                                </span>
                                    <input type="number" min={1} className="lsOptionInput" placeholder={options.room}/>
                                </div>
                            </div>
                        </div>
                        <button onClick={handleSearch} >Search</button>
                    </div>

                    <div className="listResult">
                        <SearchItem destination={destination}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
export default Lists;