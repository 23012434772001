import React from 'react';

const FeaturedItem = ({hotelName, imgSrc, id}) => {

    return (
        <div className="featuredItem">
            <img
                src={imgSrc}
                alt="" className="featuredImg"/>
            <div className="featuredTitles">
                <a href={`/checkout/${id}`}>{hotelName}</a>
            </div>
        </div>
    );
};

export default FeaturedItem;