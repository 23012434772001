import React, {useEffect, useState} from 'react';
import {hotels} from "../../Constants";
import {Link, useParams} from 'react-router-dom';
import HotelView from "../../components/hotelView/HotelView";
import "../../pages/checkout/Checkout.css"
import Navbar from "../../components/navbar/Navbar";
import {
    WriteTransactionRequest,
    RefundableContent,
    useRefundableActions,
} from 'protect-group-dynamic-refundable-react'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { dateState, refundState} from '../../components/recoil/atom';

const Checkout = () => {
    const selectedDate = useRecoilValue(dateState)
    const setSelection = useSetRecoilState(refundState)

    const [formData, setFormData] = useState({
        name: '',
        lastName: '',
        cardNum: '',
        expDate: '',
        cvv: '',
        email: '',
        address: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const { id } = useParams();
    const selectedHotel = hotels.find(item => item.id.toString() === id)
    const [refundValue, setRefundValue] = useState(0)
    const hotelPrice = parseInt(selectedHotel.price.replace("$", ""))
    const handleProtectionChange = (args) => {
        const { protectionSelected, protectionValue } = args
        setSelection(prev => ({...prev, selection: protectionSelected}))
        setRefundValue(protectionValue)
    };
    function generateBookingReference() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const length = 8;
        const randomCharacters = Array.from({ length }, () => {
            const randomIndex = Math.floor(Math.random() * characters.length);
            return characters.charAt(randomIndex);
        });
        return  randomCharacters.join('')
    }

    const bookingRef = generateBookingReference()
    const { writeSale } = useRefundableActions()
    const {updateQuoteData} = useRefundableActions()

    const myInternalState = {
        bookingCost: hotelPrice,
        numberOfTickets: 1,
        eventDate: new Date(selectedDate.startDate),
    };

    const handleMakePayment = async () => {
        setFormData(prev => ({...prev,
            name: formData.name,
            lastName: formData.lastName,
            email: formData.email,
            address: formData.address,
            bookingRef: bookingRef
        }))

        try {
            const request: WriteTransactionRequest = {
                bookingReference: bookingRef,
                customers: [
                    { firstName: formData.name, lastName: formData.lastName, email: formData.email }
                ]
            }

            const response = await writeSale(request)
        }
        catch (e) {
        }
    }

    useEffect(() => {
        const {bookingCost, numberOfTickets, eventDate} = myInternalState
        updateQuoteData({totalValue: bookingCost, numberOfTickets, eventTravelDateTime: eventDate})
    }, [myInternalState])

    return (
        <div className="coContainer">
            <Navbar/>
            <div className="coHotel">
                <h1 className="coTitle">Checkout</h1>
                <HotelView
                    item={selectedHotel}
                    refundValue={refundValue}
                />
            </div>
            <form id="payForm" className="payFormCont">
                <h2 className="coPaymentTitle font-semibold">Payment details</h2>
                <div className="coCardDetails">
                    <div className="coInputContainer">
                        <label className="coLabel" htmlFor="name">First Name</label>
                        <input
                            required
                            className="coInput"
                            type="text" id="name" name="name" value={formData.name} onChange={handleChange}/>
                    </div>
                    <div className="coInputContainer">
                        <label className="coLabel" htmlFor="lastName">Last Name</label>
                        <input
                            required
                            className="coInput"
                            type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange}/>
                    </div>
                    <div className="coInputContainer">
                        <label className="coLabel" htmlFor="email">Card Number</label>
                        <input
                            required
                            className="coInput"
                            type="number" id="cardNum" name="cardNum"/>
                    </div>
                    <div className="coInputContainer">
                        <label className="coLabel" htmlFor="email">Expiration
                            Date</label>
                        <input
                            required
                            className="coInput"
                            type="date" id="expDate" name="expDate"/>
                    </div>
                    <div className="coInputContainer">
                        <label className="coLabel" htmlFor="email">CVV</label>
                        <input
                            maxLength={3}
                            required
                            className="coInput"
                            type="number" id="cvv" name="cvv"/>
                    </div>
                </div>
                <div className="coAdress">
                    <div className="coInputContainer">
                        <label className="coLabel" htmlFor="email">Email</label>
                        <input
                            required
                            className="coInput"
                            type="email" id="email" name="email" value={formData.email} onChange={handleChange}/>
                    </div>
                    <div className="coInputContainer">
                        <label className="coLabel" htmlFor="address">Address</label>
                        <input
                            required
                            className="coInput"
                            id="address" name="address" value={formData.address} onChange={handleChange}></input>
                    </div>
                </div>

                <RefundableContent onProtectionChange={handleProtectionChange}/>

                <div className="coBtnContainer flex justify-end mt-5">
                    <Link to={`/thank you/${bookingRef}`}>
                        <button
                            form="payForm"
                            className="btnCheckout"
                            type="submit"
                            onClick={handleMakePayment}>
                            Pay ${refundValue + hotelPrice}
                        </button>
                    </Link>
                </div>
            </form>
        </div>
    )
}
export default Checkout;